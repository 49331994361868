import actions from "./actions";
import settings from "config/settings";
import {
	appSuperAdmin,
	onBoardEmployee,
	appAdmin,
	defaultPermissions,
	onBoardUser,
} from "components/constants";

const initState = {
	isLoggedIn: false,
	validatingAuthToken: false,
	loader: false,
	lang: "en",
	email: null,
	companyId: null,
	userId: null,
	name: null,
	profileImageUrl: null,
	profileLoader: false,
	isAdmin: false,
	role: null,
	changePasswordDrawerVisible: false,
	employeeId: null,
	isShowWelcomePage: false,
	userName: null,
	displayWelcomeText: false,
	isGettingPermissions: false,
	userPermissions: [],
	companyName: null,
	companyLogoUrl: null,
	companyEmailLogo: null,
	companyEmailName: null,
	inviteEmailTrigger: null,
	meetingInviteBefore: null,
	isSendRequestOTP: false,
	otpButtonLoader: false,
	isOtpPageActive: false,
	timerActive: false,
	authEmailPrefix: [],
	tempEmpPhoneNumber: "",
	twilioAccountSid: null,
	twilioAuthToken: null,
	twilioMessagingServiceSid: null,
	autoAddConditionSettings: [],
};

export default function authReducer(state = initState, action) {
	switch (action.type) {
		case actions.AUTHENTICATE_USER: {
			return {
				...state,
				loader: true,
			};
		}
		case actions.AUTHENTICATE_USER_SUCCESS: {
			const { email, id, company, name, avatar_url, is_admin } =
				action.payload.user;
			return {
				...state,
				loader: false,
				isLoggedIn: true,
				validatingAuthToken: false,
				email: email,
				companyId: company.id,
				userId: id,
				name: name,
				profileImageUrl: avatar_url,
				isAdmin: is_admin,
				role: settings.DEFAULT_TEMPLATE_USER_EMAIL.split(",").includes(email)
					? appSuperAdmin
					: is_admin
					? appAdmin
					: onBoardUser,
				companyName: company.name,
				companyLogoUrl: company.logo_url,
				companyEmailLogo: company.company_email_logo,
				companyEmailName: company.company_email_name,
				authEmailPrefix: company.sendgrid_prefixs,
				inviteEmailTrigger: company.invite_email,
				meetingInviteBefore: company.meeting_invite_before_days,
				companyDomainDetails: company.sendgrid_details,
				sendgridDomainStatus: company.sendgrid_domain_status,
				sendGridDomainCreated: company.sendgrid_domain_created,
				companySubDomain: company.sub_domain,
				twilioAccountSid: company.twilio_account_sid,
				twilioAuthToken: company.twilio_auth_token,
				twilioMessagingServiceSid: company.twilio_messaging_service_sid,
				autoAddConditionSettings: company.auto_add_condition_settings,
			};
		}
		case actions.AUTHENTICATE_USER_FAILURE: {
			return {
				...state,
				loader: false,
				isLoggedIn: false,
				validatingAuthToken: false,
				email: null,
				companyId: null,
				userId: null,
				name: null,
				role: null,
				companyName: null,
				companyLogoUrl: null,
			};
		}
		case actions.LOGOUT_USER: {
			return {
				...state,
				loader: true,
			};
		}
		case actions.LOGOUT_USER_FAILURE: {
			return {
				...state,
				loader: false,
			};
		}
		case actions.VALIDATE_AUTH_TOKEN: {
			return {
				...state,
				validatingAuthToken: true,
			};
		}
		case actions.SEND_PASSWORD_RESET_LINK: {
			return {
				...state,
				loader: true,
			};
		}
		case actions.SEND_PASSWORD_RESET_LINK_SUCCESS: {
			return {
				...state,
				loader: false,
			};
		}
		case actions.SEND_PASSWORD_RESET_LINK_FAILURE: {
			return {
				...state,
				loader: false,
			};
		}
		case actions.RESET_PASSWORD: {
			return {
				...state,
				loader: true,
			};
		}
		case actions.RESET_PASSWORD_SUCCESS: {
			return {
				...state,
				loader: false,
			};
		}
		case actions.RESET_PASSWORD_FAILURE: {
			return {
				...state,
				loader: false,
			};
		}
		case actions.UPDATE_PROFILE_IMAGE_SUCCESS: {
			return {
				...state,
				profileImageUrl: action.imageUrl,
				profileLoader: false,
			};
		}
		case actions.UPDATE_PROFILE_IMAGE: {
			return {
				...state,
				profileLoader: true,
			};
		}
		case actions.UPDATE_PROFILE_IMAGE_FAILURE:
			return {
				...state,
				profileLoader: false,
			};
		case actions.CHANGE_PASSWORD_MODAL_VISIBLE: {
			return {
				...state,
				changePasswordDrawerVisible: action.payload,
			};
		}
		case actions.AUTHENTICATE_EMPLOYEE: {
			return {
				...state,
				loader: true,
			};
		}
		case actions.AUTHENTICATE_EMPLOYEE_SUCCESS: {
			const {
				email,
				id,
				company,
				first_name,
				last_name,
				avatar_url,
				is_admin,
			} = action.payload.employee;
			return {
				...state,
				loader: false,
				isLoggedIn: true,
				validatingAuthToken: false,
				email: email,
				companyId: company.id,
				employeeId: id,
				name: `${first_name} ${last_name}`,
				profileImageUrl: avatar_url,
				isAdmin: is_admin,
				role: onBoardEmployee,
			};
		}
		case actions.AUTHENTICATE_EMPLOYEE_FAILURE: {
			return {
				...state,
				loader: false,
				isLoggedIn: false,
				validatingAuthToken: false,
				email: null,
				companyId: null,
				userId: null,
				name: null,
				role: null,
				employeeId: null,
			};
		}
		case actions.LOGOUT_EMPLOYEE: {
			return {
				...state,
				loader: true,
			};
		}
		case actions.LOGOUT_EMPLOYEE_FAILURE: {
			return {
				...state,
				loader: false,
			};
		}
		case actions.VALIDATE_EMPLOYEE_AUTH_TOKEN: {
			return {
				...state,
				validatingAuthToken: true,
			};
		}
		case actions.EMPLOYEE_SEND_PASSWORD_RESET_LINK: {
			return {
				...state,
				loader: true,
			};
		}
		case actions.EMPLOYEE_SEND_PASSWORD_RESET_LINK_SUCCESS: {
			return {
				...state,
				loader: false,
			};
		}
		case actions.EMPLOYEE_SEND_PASSWORD_RESET_LINK_FAILURE: {
			return {
				...state,
				loader: false,
			};
		}
		case actions.EMPLOYEE_RESET_PASSWORD: {
			return {
				...state,
				loader: true,
			};
		}
		case actions.EMPLOYEE_RESET_PASSWORD_SUCCESS: {
			return {
				...state,
				loader: false,
			};
		}
		case actions.EMPLOYEE_RESET_PASSWORD_FAILURE: {
			return {
				...state,
				loader: false,
			};
		}
		case actions.EMPLOYEE_CHANGE_PASSWORD: {
			return {
				...state,
				loader: true,
			};
		}
		case actions.EMPLOYEE_CHANGE_PASSWORD_SUCCESS: {
			return {
				...state,
				loader: false,
				changePasswordDrawerVisible: false,
			};
		}
		case actions.EMPLOYEE_CHANGE_PASSWORD_FAILURE: {
			return {
				...state,
				loader: false,
			};
		}
		case actions.SET_SHOW_WELCOME_PAGE:
			return {
				...state,
				isShowWelcomePage: action.payload,
				userName: action.name,
			};
		case actions.SET_DISPLAY_WELCOME_PAGE:
			return {
				...state,
				displayWelcomeText: action.payload,
			};
		case actions.GET_USER_PERMISSIONS:
			return {
				...state,
				isGettingPermissions: true,
			};
		case actions.GET_USER_PERMISSIONS_SUCCESS:
			return {
				...state,
				isGettingPermissions: false,
				userPermissions:
					action.payload.role.permissions.concat(defaultPermissions),
			};
		case actions.GET_USER_PERMISSIONS_FAILURE:
			return {
				...state,
				isGettingPermissions: false,
			};
		case actions.UPDATE_EMAIL_SETTINGS_DETAILS:
			return {
				...state,
				companyEmailLogo: action.companyLogoUrl,
			};
		case actions.UPDATE_COMPANY_EMAIL_DATA_SUCCESS: {
			const {
				company_email_logo,
				company_email_name,
				invite_email,
				meeting_invite_before_days,
			} = action.payload;
			return {
				...state,
				companyEmailName: company_email_name,
				companyEmailLogo: company_email_logo,
				inviteEmailTrigger: invite_email,
				meetingInviteBefore: meeting_invite_before_days,
			};
		}
		case actions.UPDATE_DOMAIN_DETAILS: {
			return {
				...state,
				companyDomainDetails: action.payload.sendgrid_details,
				sendgridDomainStatus: action.payload.sendgrid_domain_status,
				sendGridDomainCreated: action.payload.sendgrid_domain_created,
			};
		}
		case actions.LOGIN_WITH_OTP: {
			return {
				...state,
				tempEmpPhoneNumber: action.payload,
				otpButtonLoader: true,
			};
		}
		case actions.LOGIN_WITH_OTP_SUCCESS: {
			return {
				...state,
				otpButtonLoader: false,
				isOtpPageActive: true,
				timerActive: true,
			};
		}
		case actions.LOGIN_WITH_OTP_FAILURE: {
			return {
				...state,
				otpButtonLoader: false,
			};
		}
		case actions.OTP_AUTHENTICATION: {
			return {
				...state,
				otpButtonLoader: true,
				isSendRequestOTP: true,
			};
		}
		case actions.OTP_AUTHENTICATION_SUCCESS: {
			return {
				...state,
				otpButtonLoader: false,
				isOtpPageActive: false,
				timerActive: false,
			};
		}
		case actions.OTP_AUTHENTICATION_FAILURE: {
			return {
				...state,
				otpButtonLoader: false,
				timerActive: false,
			};
		}
		case actions.SET_OTP_TIMER_EXPIRE: {
			return {
				...state,
				isSendRequestOTP: false,
				timerActive: false,
			};
		}
		case actions.BACK_TO_LOGIN: {
			return {
				...state,
				isOtpPageActive: false,
			};
		}
		case actions.UPDATE_TWILIO_CONFIG: {
			return {
				...state,
				twilioAccountSid: action.payload.twilio_account_sid,
				twilioAuthToken: action.payload.twilio_auth_token,
				twilioMessagingServiceSid: action.payload.twilio_messaging_service_sid,
			};
		}
		default:
			return state;
	}
}
